<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <div class="flex items-center">
        <img src="@/assets/images/icons8-open-envelope-clock-96.png" class="h-20 ml-2" />
        <div class="text-xl font-bold">تکرار پست در گروه</div>
      </div>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Group"></Icon>
        {{ group.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ group.gid }}</div>
    </template>
    <template #default>
      <div class="mb-5">
        <Button
          :to="{
            name: 'GroupOwnerSettingRepeatNew',
            params: { id: $store.state.user.id, groupId: group.id },
          }"
          class="mt-3 text-sm h-11 lg:text-lg"
          color="cyan"
        >
          اضافه کردن ساعت جدید
        </Button>
      </div>
      <div class="grid lg:grid-cols-3 sm:grid-cols-2 gap-3">
        <div
          v-for="post in group.posts"
          :key="post.id"
          class="bg-blueGray-100 border border-blueGray-200 rounded-md p-3"
        >
          <div class="border-b border-blueGray-200 pb-3">
            <img src="@/assets/images/icons8-watch-96.png" class="inline-block h-10" />
            {{ post.name }}
          </div>
          <div class="flex p-2">
            <div class="w-1/2">توسط</div>
            <div class="w-1/2 text-left">
              {{ post.user.first_name }} {{ post.user.last_name }}
            </div>
          </div>
          <div class="flex p-2">
            <div class="w-1/2">نوع ارسال</div>
            <div class="w-1/2 text-left">{{ post.forward ? "forward" : "copy" }}</div>
          </div>
          <div class="flex p-2">
            <div class="w-1/2">وضعیت: {{ post.active ? "فعال" : "غیر فعال" }}</div>
            <div class="w-1/2 text-left">خطا: {{ post.send_error }}</div>
          </div>
          <div class="flex p-2">
            <div class="w-1/2">آخرین ارسال</div>
            <div class="w-1/2 text-left" dir="ltr">
              {{ post.last_at ? new Date(post.last_at?.def).toLocaleTimeString() : "-" }}
            </div>
          </div>
          <div class="flex p-2">
            <div class="w-1/2">ارسال بعدی</div>
            <div class="w-1/2 text-left" dir="ltr">
              {{ post.next_at ? new Date(post.next_at?.def).toLocaleTimeString() : "-" }}
            </div>
          </div>
          <Button
            :to="{
              name: 'GroupOwnerSettingRepeatEdit',
              params: { id: $store.state.user.id, groupId: group.id, repeatId: post.id },
            }"
            class="mt-3 text-sm h-11 lg:text-lg"
            color="teal"
          >
            ویرایش
          </Button>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";

import Button from "@/components/Button.vue";

export default {
  components: {
    BodySimple,
    Icon,
    Button,
  },
  data() {
    return {
      group: {},
      firstLoading: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      // $this.firstLoading = true;
      $this.$axios
        .get("/api/group-posts/" + $this.$route.params.groupId, {
          params: {
            id: $this.$store.state.user.id,
            // search: $this.searchTools,
            // date: $this.date,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.group = response.data.group;
          // $this.users = response.data.users;
          // $this.date = response.data.date;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.firstLoading = false;
          $this.sending = false;
        });
    },
  },
};
</script>

<style></style>
